/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
// import UsersContext from '../../../context/users';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import ResetPassword from './ResetPassword';
import InsertNewPassword from './InsertNewPassword';
import EmailForOtp from './ResetPassword/emailForOtp';
import { LoginService } from '../../../services/loginService';
import './style.css';
import CustomizedInputs from '../CustomizedInputs';
import { AbstractService } from '../../../services/abstractService';
import packageInfo from '../../../../package.json';

export default function LoginForm(props) {
  // PROVIDER DE CONTEXT API
  // const { getUser, user } = useContext(UsersContext);
  const [password, setPassword] = useState('');
  const [userdata, setUsername] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [btnBackground, setBtnBackground] = useState('#D1D1D1');

  const [login, setLogin] = useState(true);
  const [loginTitle, setLoginTitle] = useState(
    'Ingresá tu usuario y contraseña Tipie',
  );
  const [resetPassword, setResetPassword] = useState(false);
  const [insertNewPassword, setInsertNewPassword] = useState(false);

  const [errorUser, setErrorUser] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [showGoBack, setShowGoBack] = useState(false);

  const [otpsent, setOtpsent] = useState(false);
  const [usernameResetPassword, setUsernameResetPassword] = useState(false);
  const [errorUserResetPassword, setErrorUserResetPassword] = useState(false);

  const [accountId, setAccountId] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);

  const [showLoading, setShowLoading] = useState(false);
  const [showLoadingReset, setShowLoadingReset] = useState(false);

  const history = useHistory();

  // EYE ICON
  const [visibilityPassword, setVisibilityPassword] = useState(false);

  const togglePassword = () => {
    if (visibilityPassword) {
      setVisibilityPassword(false);
    } else {
      setVisibilityPassword(true);
    }
  };
  // EYE ICON

  useEffect(() => {
    if (userdata.length > 1 && password.length > 1) {
      setDisabled(false);
      setBtnBackground('#E98A15');
    } else if (usernameResetPassword.length > 1) {
      setDisabled(false);
      setBtnBackground('#E98A15');
    } else {
      setDisabled(true);
      setBtnBackground('#D1D1D1');
    }
  }, [userdata, password, usernameResetPassword]);

  useEffect(() => {
    if (login) {
      setLoginTitle('Ingresá tu usuario y contraseña Tipie');
    } else if (resetPassword) {
      setLoginTitle(
        'Ingresá la casilla de mail registrada. Te vamos a mandar un código de recuperación.',
      );
    } else if (otpsent) {
      setLoginTitle('Ingresá el código que te enviamos al mail registrado.');
    } else if (insertNewPassword) {
      setLoginTitle('Ingresá tu nueva contraseña');
    }
  }, [login, resetPassword, insertNewPassword, otpsent]);

  // if (localStorage.getItem('loggedUser')) {
  //     return <Redirect to="/home" />
  // }
  const handleSubmitResetPassword = async () => {
    setBtnBackground('#D1D1D1');
    setDisabled(true);
    setShowLoadingReset(true);
    try {
      const body = {
        receptor_email: usernameResetPassword,
        reason: 'forgot_password',
      };
      const sendOtpByEmail = await AbstractService({
        url: `${process.env.REACT_APP_BFF_WEB_URL}/users/otp/send`,
        method: 'post',
        data: body,
      });
      setResetPassword(false);
      setOtpsent(true);
      setShowGoBack(false);
      setAccountId(sendOtpByEmail.userData.id);
      setShowLoadingReset(false);
    } catch (error) {
      setErrorText('No se pudo enviar el código. Intenta nuevamente');
      setErrorUserResetPassword(true);
      setDisabled(false);
      setShowLoadingReset(false);
    }
  };

  const handleUsernameResetPassword = (e) => {
    setUsernameResetPassword(e.target.value);
    setDisabled(false);
    setBtnBackground('#E98A15');
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleResetPassword = () => {
    setShowGoBack(true);
    setLogin(false);
    setResetPassword(true);
  };

  const handleInsertNewPassword = () => {
    setResetPassword(false);
    setInsertNewPassword(true);
  };

  const handleGoBack = () => {
    if (resetPassword && !login && !insertNewPassword && !otpsent) {
      setResetPassword(false);
      setShowGoBack(false);
      setLogin(true);
      setErrorText('');
    } else if (insertNewPassword && !login && !resetPassword && !otpsent) {
      setResetPassword(false);
      setInsertNewPassword(false);
      setLogin(false);
      setOtpsent(true);
      setErrorText('');
    } else if (!resetPassword && !login && !insertNewPassword && otpsent) {
      setResetPassword(false);
      setInsertNewPassword(false);
      setLogin(true);
      setOtpsent(false);
      setErrorText('');
    } else if (!resetPassword && !login && !insertNewPassword && !otpsent) {
      setResetPassword(false);
      setInsertNewPassword(false);
      setLogin(true);
      setOtpsent(false);
      setErrorText('');
    }
  };

  const deleteErrorMessage = () => {
    setErrorUser(false);
    setErrorPassword(false);
    setErrorUserResetPassword(false);
  };

  const passwordRule =
    /\/([A-Z])[0-9]|([0-9])[A-Z]|([A-Z].+)[0-9]|([0-9].+)[A-Z]\/gm/;

  const handleFormSubmit = async (e) => {
    setDisabled(true);
    setShowLoading(true);
    setBtnBackground('#D1D1D1');
    e.preventDefault();

    // hay que chequear si hace login con un email o con un username
    const body = {
      userdata: userdata.trim(),
      password: password.trim(),
    };

    if (!passwordRule.test(password) || password.trim().length < 8) {
      setErrorText('La contraseña no es válida');
      setErrorPassword(true);

      setShowLoading(false);
      return;
    }
    try {
      const responseLogin = await LoginService(body);
      if (responseLogin.status_code === 200) {
        localStorage.setItem('loggedUser', responseLogin.payload.token); // acá se guarda el token en el storage
        localStorage.setItem('userdata', userdata.trim());
        // return <Redirect to="/home" />;
        if (responseLogin.first_login) {
          /*
                    setLogin(false);
                    setInsertNewPassword(true);
                    */
          // NOTE: first login logic must be bypassed as
          // new pasword flow isn't integrated yet.
          history.push('/home', { from: 'LOGIN' });
        }
        // else {
        // const location = {
        //     pathname: '/home',
        //     state: { fromLogin: true }
        // }
        // history.push('/home', { from: 'LOGIN' });
        // }
      }

      setShowLoading(false);
      // if (responseLogin.first_login) {
      // } else {
      //     localStorage.setItem('loggedUser', responseLogin.payload.token); // acá se guarda el token en el storage
      //     return <Redirect to="/home" />;
      //     history.push("/home");
      // }
    } catch (error) {
      setErrorText('Contraseña o usuario incorrectos');
      setErrorPassword(true);
      setErrorUser(false);
      setShowLoading(false);
    }
  };

  const validationTrue = () => {
    setOtpsent(false);
    setResetPassword(false);
    setLogin(false);
    setInsertNewPassword(true);
    setShowGoBack(false);
  };

  return (
    <div
      className='mainView'
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <div style={{ width: '100%', flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
        <Card className='rootLogin' variant='outlined'>
          <CardContent>
            {showGoBack ? (
              <div className='loginGoBackDiv' onClick={handleGoBack}>
                <ion-icon name='chevron-back-outline' />
                <p className='loginGoBackParagraph'>Volver</p>
              </div>
            ) : null}

            <div
              style={{
                width: '100%',
                margin: '0 auto',
                marginBottom: 10,
                marginLeft: 25,
                paddingTop: showGoBack ? 10 : 25,
              }}
            >
              <h1
                className={
                  otpsent || resetPassword ? 'otpMainTitle' : 'loginMainTitle'
                }
              >
                {loginTitle}
              </h1>
            </div>
            {login ? (
              <div className='formLogin'>
                <div className='divLabelLogin'>
                  <CustomizedInputs
                    label='Mail o usuario'
                    handleUsername={handleUsernameChange}
                    user={userdata}
                    error={errorPassword || errorUser}
                    deleteError={deleteErrorMessage}
                    handleSubmit={handleFormSubmit}
                    value={userdata}
                  />
                  <div
                    className={
                      errorUser ? 'loginErrorDiv' : 'loginErrorDivNone'
                    }
                  >
                    <ion-icon name='alert-circle-outline' />
                    <p className='loginErrorText'>{errorText}</p>
                  </div>
                </div>

                <div className='divLabelLogin'>
                  <div className='loginFormDiv'>
                    <CustomizedInputs
                      label='Contraseña'
                      handlePassword={handlePasswordChange}
                      password={!visibilityPassword ? password : null}
                      error={errorPassword || errorUser}
                      deleteError={deleteErrorMessage}
                      handleSubmit={handleFormSubmit}
                      value={password}
                    />
                    {visibilityPassword ? (
                      <VisibilityOffIcon
                        className='loginEyeIcon'
                        onClick={togglePassword}
                      />
                    ) : (
                      <VisibilityIcon
                        className='loginEyeIcon'
                        onClick={togglePassword}
                      />
                    )}
                  </div>
                  <div
                    className={
                      errorPassword ? 'loginErrorDiv' : 'loginErrorDivNone'
                    }
                  >
                    <ion-icon name='alert-circle-outline' />
                    <p className='loginErrorText'>{errorText}</p>
                  </div>
                </div>

                <div className='buttonsDivLogin'>
                  <div
                    className='forgotPasswordDiv'
                    onClick={handleResetPassword}
                  >
                    <p>Olvidé la contraseña</p>
                  </div>

                  <button
                    onClick={handleFormSubmit}
                    disabled={disabled}
                    type='button'
                    style={{
                      width: 167,
                      borderRadius: 24,
                      fontSize: 14,
                      fontFamily: 'Inter, Semi Bold',
                      backgroundColor: btnBackground,
                      color: 'white',
                      margin: 0,
                      borderWidth: 0,
                      cursor: disabled ? 'not-allowed' : 'pointer',
                    }}
                  >
                    {showLoading ? (
                      <div
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                        }}
                      >
                        <CircularProgress color='inherit' size={14} />
                      </div>
                    ) : (
                      <p>Iniciar sesión</p>
                    )}
                  </button>
                </div>
              </div>
            ) : resetPassword ? (
              <EmailForOtp
                handleSubmitResetPassword={handleSubmitResetPassword}
                handleUsernameResetPassword={handleUsernameResetPassword}
                errorUserResetPassword={errorUserResetPassword}
                errorText={errorText}
                deleteError={deleteErrorMessage}
                usernameResetPassword={usernameResetPassword}
                disabled={disabled}
                btnBackground={btnBackground}
                showLoading={showLoadingReset}
              />
            ) : otpsent ? (
              <ResetPassword
                insertNewPassword={handleInsertNewPassword}
                usernameResetPassword={usernameResetPassword}
                accountId={accountId}
                pushToken={setSessionToken}
                validationTrue={validationTrue}
                handleSubmitResetPassword={handleSubmitResetPassword}
                handleGoBack={handleGoBack}
              />
            ) : (
              <InsertNewPassword
                session={sessionToken}
                showModal={props.showModal}
                receptorEmail={usernameResetPassword}
              />
            )}
          </CardContent>
        </Card>
      </div>
      <div
        style={{
          textAlign: 'center',
          padding: '20px 0',
          fontSize: '12px',
          color: '#808080',
          width: '100%',
          marginTop: '10%'
        }}
      >
        {packageInfo.version}-dev
      </div>
    </div>
  );
}
